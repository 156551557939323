angular.module('LeasePilot').controller('formUpdatesController', [
  '$scope',
  '$rootScope',
  'FormService',
  'CompanyService',
  'AllOverrideService',
  '$mdDialog',
  'FreeTextToSmlService',
  '$timeout',
  'LeaseEditorService',
  function(
    $scope,
    $rootScope,
    FormService,
    CompanyService,
    AllOverrideService,
    $mdDialog,
    FreeTextToSmlService,
    $timeout,
    LeaseEditorService,
  ) {
    $scope.hideCompletedOverrides = false;
    $scope.hideCompletedBuildingOverrides = false;

    const confirmTemplate =
      '<div class="md-dialog-content confirm-modal">' +
      '<p class="title">{{title}}</p>' +
      '<p class="message">{{message}}</p>' +
      '<div class="modal-actions">' +
      '<button class="btn blue" ng-click="ok()">Start</a>' +
      '<button class="btn blue-outline" ng-click="cancel()">Cancel</buttom>' +
      '</div>' +
      '</div>';

    $scope.confirmFormUpdate = function() {
      var confirm = $mdDialog.confirm({
        skipHide: true,
        template: confirmTemplate,
        clickOutsideToClose: true,
        controller:
          ('confirmController', ['$scope', '$mdDialog', confirmController]),
      });

      $mdDialog.show(confirm);

      function confirmController(confirmScope, $mdDialog) {
        confirmScope.title = 'Update SML';
        confirmScope.message =
          'Notice! Starting the SML update process will block ' +
          _.startCase($scope.company.name) +
          ' users from editing Admin Form for the next deployment.';
        confirmScope.cancel = function() {
          $mdDialog.cancel();
        };
        confirmScope.ok = function() {
          $scope.form.state = 'update_started';
          $scope.form.update().then(function(response) {
            $rootScope.form = response;
            $mdDialog.cancel();
          });
        };
      }
    };

    $scope.init = function(data) {
      FormService.get({
        id: data.formId,
      }).then(function(response) {
        $rootScope.form = response;
        AllOverrideService.query({
          formId: data.formId,
          includeOnlyPublishedChanges: true,
          includeDefaultSection: true,
        }).then(function(response) {
          $scope.setOverrides(response);
        });

        CompanyService.get({
          id: $rootScope.form.companyId,
        }).then(function(response) {
          $scope.company = response;
        });
      });
    };

    $scope.setOverrides = function(response) {
      $scope.overrides = _.reject(response.overrides, function(o) {
        return o.overrideType === 'ordered_listitem';
      });
      $scope.listItems = _.find(response.overrides, function(o) {
        return o.overrideType === 'ordered_listitem';
      });

      if ($scope.listItems) {
        $scope.listItems = JSON.parse($scope.listItems.orderedListitem);

        //insert `inserted-list-items` header
        for (var i = 0; i < $scope.listItems.length; i++) {
          var listItem = $scope.listItems[i];
          var newIndex = _.sortedIndexBy(
            $scope.overrides,
            { sectionId: listItem.headerFreeText },
            'sectionId',
          );
          if($scope.overrides[newIndex]){
            $scope.overrides[newIndex].header = listItem;
          }
          
          if(listItem.json){
            let  jsonElement = HTMLElement.fromJson(listItem.json,true);
            let sectionIdEl = jsonElement.querySelector('[free-text]');
            let sectionId = sectionIdEl ? sectionIdEl.getAttribute('free-text') : null;
            if(sectionId) {
              let item = $scope.overrides.filter((o)=>{ return o.sectionId == sectionId  });
              if(item.length == 0){
                let newOne = {...$scope.overrides[i]};
                newOne.text = jsonElement.innerHTML;
                newOne.defaultSection = '';
                newOne.header = listItem;
                newOne.sectionId = sectionId;
                $scope.overrides.push(newOne);
              }
            }
            
          }
        }
      }
      $scope.overrides =  $scope.overrides.sort((a,b)=>{ return a.sectionId > b.sectionId ? 1 : -1 })

      $scope.buildingOverrides = response.buildingOverrides;
      $timeout(function() {
        $('[col-resizeable]').trigger('dataChanged');
      });
    };

    $scope.toggleOverrides = function() {
      $scope.hideCompletedOverrides = !$scope.hideCompletedOverrides;
      $timeout(function() {
        $('table.overrides [col-resizeable]').trigger('dataChanged');
      });
    };

    $scope.toggleBuildingOverrides = function() {
      $scope.hideCompletedBuildingOverrides = !$scope.hideCompletedBuildingOverrides;
      $timeout(function() {
        $('table.building-overrides [col-resizeable]').trigger('dataChanged');
      });
    };

    $scope.toggleUpdateOverride = function(override) {
      override.updated = !override.updated;
      override.update();
      $timeout(function() {
        $('[col-resizeable]').trigger('dataChanged');
      });
    };

    $scope.copyToClipboard = function(text) {
      var sel = rangy.getSelection();
      var elToCopy;
      if (sel.isCollapsed) {
        elToCopy = FreeTextToSmlService.convert($(text));
      } else {
        elToCopy = sel.toHtml();
      }

      elToCopy = elToCopy.wrapAll('<p/>').parent();

      LeaseEditorService.removeMarkers(elToCopy);
      elToCopy.find('.override-color').removeClass('override-color');
      elToCopy.addClass('lease');

      // execute `copy` command
      $('body').append(elToCopy);
      var range = rangy.createRange();
      range.selectNodeContents(elToCopy[0]);
      var sel = rangy.getSelection();
      sel.setSingleRange(range);
      document.execCommand('copy');
      elToCopy.remove();
    };
  },
]);
